//Primary Dependencies
import { createRouter, createWebHashHistory } from 'vue-router';
import env from '@/env';
import { categories } from '@/js/constants/eventCategories';
import LookupPage from '@/views/LookupPage.vue';
import GiftReceiptLookupPage from '@/views/GiftReceiptLookupPage/GiftReceiptLookupPage.vue';
import Exchange from '@/views/Exchange.vue';
import Credit from '@/views/Credit.vue';
import Shop from '@/views/ShopNow.vue';
import ShopNowProduct from '@/views/Shop/ShopNowProduct.vue';
import ShopNowSidecart from '@/views/Shop/ShopNowSidecart.vue';
import ShopNowPlaceholder from '@/views/Shop/ShopNowPlaceholder.vue';
import StatusPage from '@/views/StatusPage.vue';
import ReviewPage from '@/views/ReviewPage.vue';
import { datadogRum } from '@datadog/browser-rum';
import store from '@/store';
import TrackingPage from '@/views/ForwardTracking/TrackingPage.vue';
import TrackingErrorPage from '@/views/ForwardTracking/ErrorPage.vue';
import TrackingLookupPage from '@/views/ForwardTracking/TrackingLookup.vue';

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { top: 0 };
  },
  routes: [
    {
      path: '/',
      component: LookupPage,
      name: 'lookup',
      meta: {
        trackingCategory: categories.LOOKUP,
      },
    },
    {
      path: '/gift',
      component: LookupPage,
      name: 'gift',
      meta: {
        trackingCategory: categories.GIFT_LOOKUP,
      }
    },
    {
      path: '/gift-receipt',
      component: GiftReceiptLookupPage,
      name: 'giftReceipt',
      meta: {
        layout: 'GiftReceiptLayout',
        trackingCategory: categories.GIFT_RECEIPT_LOOKUP,
      }
    },
    {
      path: '/exchange',
      component: Exchange,
      name: 'exchange',
      meta: {
        layout: 'NavLayout',
        previous: '/lookup',
        cart: true,
        trackingCategory: categories.EXCHANGE,
      }
    },
    {
      path: '/credit',
      component: Credit,
      name: 'credit',
      meta: {
        layout: 'NavLayout',
        previous: '/exchange',
        cart: true,
        trackingCategory: categories.CREDIT,
      }
    },
    {
      path: '/shop',
      component: Shop,
      meta: {
        layout: 'NavLayout',
        navPosition: 'sticky',
        legacyNav: true,
        previous: '/credit',
        cart: true,
        background: 'solid',
        trackingCategory: categories.SHOP_NOW,
      },
      children: [
        {
          path: 'collection/:id?/:name?',
          //need an empty component here since Vue 3 requires a component to be passed in
          component: ShopNowPlaceholder,
          alias: '/shop',
          name: 'shop',
          meta: {
            layout: 'NavLayout',
            navPosition: 'sticky',
            legacyNav: true,
            previous: '/credit',
            cart: true,
            background: 'solid',
            trackingCategory: categories.SHOP_NOW,
          }
        },
        {
          path: 'collection/:id/product/:productId/:name?',
          name: 'shopnowproduct',
          component: ShopNowProduct,
          meta: {
            layout: 'NavLayout',
            navPosition: 'sticky',
            legacyNav: true,
            previous: '/credit',
            cart: true,
            background: 'solid',
            trackingCategory: categories.SHOP_NOW,
          }
        },
        {
          path: 'cart',
          name: 'shopnowcart',
          component: ShopNowSidecart,
          meta: {
            layout: 'NavLayout',
            navPosition: 'sticky',
            legacyNav: true,
            previous: '/credit',
            cart: true,
            background: 'solid',
            trackingCategory: categories.SHOP_NOW,
          }
        },
      ]
    },
    {
      path: '/review',
      component: ReviewPage,
      name: 'review',
      meta: {
        layout: 'NavLayout',
        legacyNav: true,
        previous: '/credit',
        trackingCategory: categories.REVIEW,
      }
    },
    // Old ending page. Replaced by the return status page
    {
      path: '/confirm',
      redirect: '/'
    },

    {
      path: '/link',
      component: () => import('@/views/DeepLink'),
      name: 'link',
      meta: {
        trackingCategory: categories.DEEPLINK,
      },
      children: [
        {
          path: '/link/:token',
          component: () => import('@/views/DeepLink'),
          name: 'link',
          meta: {
            trackingCategory: categories.DEEPLINK,
          }
        },
      ],
    },
    {
      path: '/gift-link/:token',
      component: () => import('@/views/GiftReceiptLookupPage/RecipientDeeplink'),
      name: 'gift-link',
      meta: {
        layout: 'GiftReceiptLayout',
        trackingCategory: categories.GIFT_DEEPLINK,
      },
    },
    {
      path: '/cart',
      component: () => import('@/views/OnstoreCart'),
      name: 'cartBase',
      meta: {
        trackingCategory: categories.CART,
      }
    },
    {
      path: '/cart/:token',
      component: () => import('@/views/OnstoreCart'),
      name: 'cart',
      meta: {
        trackingCategory: categories.CART,
      }
    },
    {
      path: '/cart/v2/:token/:returnKey?',
      component: () => import('@/views/OnstoreCart'),
      name: 'cartv2',
      meta: {
        trackingCategory: categories.CART,
      }
    },
    {
      name: 'StatusPage',
      path: '/return/:hash',
      component: StatusPage,
      meta: {
        layout: 'NavLayout',
        legacyNav: true,
        background: 'solid',
        trackingCategory: categories.RETURN_STATUS,
      }
    },
    {
      path: '/tracking/:trackingHash',
      component: TrackingPage,
      name: 'tracking-view',
      beforeEnter(to, from, next) {
        // feature flags may not be available, by calling next we can go through the flow of loading the data, then re-entering this guard.
        if (!store.state.feature_flags) {
          next();
        } else {
          next();
        }
      },
    },
    {
      path: '/tracking-error',
      component: TrackingErrorPage,
      name: 'tracking-error'
    },
    {
      path: '/lookup',
      component: TrackingLookupPage,
      name: 'tracking-lookup'
    }
    // {
    //   path: '/:pathMatch(.*)*',
    //   redirect: '/',
    // },
  ]
});

// Set referrer to be used with back button
router.beforeEach(async (to, from, next) => {
  //Add Datadog custom views to account for hash mode
  datadogRum.startView(to.name);

  if (to.query.source) {
    store.commit('updateData', { name: 'source', data: to.query.source, save: true });
  }

  if (to.query.embedded) {
    store.commit('updateData', {
      name: 'isEmbeddedPortal',
      data: true,
      save: true,
    });
  }

  // Handle the loop.gift `/` route based on shop setting & env variable
  if (
    to.path === '/' &&
    env('VITE_GIFT_DOMAIN')
  ) {
    next({
      name: 'giftReceipt',
      query: to.query
    });
  }

  // Recipient deeplink routing when Gift Receipt flow is enabled
  if (
    to.name === 'link' &&
    env('VITE_GIFT_DOMAIN')
  ) {
    next({
      name: 'gift-link',
      params: to.params
    });
  }

  next(vm => {
    vm.referrer = from && from.fullPath;
  });
});

export default router;
